import React from "react";
import imageToBase64 from 'image-to-base64/browser';
import Image from 'react-bootstrap/Image'
import { Dot } from "./dot";
import Question from "./PopUp";
import './config.js';
import {Container,Row,Col} from 'react-bootstrap';
import Logo from './../../Images/YOTAP LOGO FULL.png';
import ShopTag from './../../Images/iconSplash1.png';
import addTOcontact from './../../Images/addTOcontact.png';

import Shop_Ezpop from './../../Images/Shop Ezpop.png';
import DownloadPLayStore from './../../Images/DownloadPLayStore.png';
import AppleStore from './../../Images/AppleStore.png';
import shareIcon from './../../Images/share_icon.png';
import UserImg from './../../Images/download.png';
import Image1 from './../../Images/Instagram.png';
import Image2 from './../../Images/Snapchat.png';
import Image3 from './../../Images/Twitter.png';
import Image4 from './../../Images/Facebook.png';
import Image5 from './../../Images/LinkedIn.png';
import Image6 from './../../Images/WhatsApp.png';
import Image7 from './../../Images/Mail1.png';
import Image8 from './../../Images/YouTube.png';
import Image9 from './../../Images/CashApp.png';



import Image10 from './../../Images/Website.png';
import Image11 from './../../Images/PayPal.png';
import Image12 from './../../Images/Website.png';
import Image13 from './../../Images/Website.png';
import Image14 from './../../Images/Soundcloud 1.png';
import Image15 from './../../Images/Spotify.png';
import Image16 from './../../Images/Text.png';
import Image17 from './../../Images/TikTok.png';
import Image18 from './../../Images/Website.png';
import Image19 from './../../Images/Twitch.png';
import Image20 from './../../Images/Apple Music.png';
import Image21 from './../../Images/OnlyFans.png';
import Image22 from './../../Images/Venmo.png';
import Image23 from './../../Images/Clubhouse.png';
import Image24 from './../../Images/Phone.png';
import Image25 from './../../Images/Pintrest.png';
import Image26 from './../../Images/Discord.png';
import Image27 from './../../Images/Document.png';
import Image28 from './../../Images/Menu.png';
import Image29 from './../../Images/Reddit.png';
import Image30 from './../../Images/WeChat.png';
import Image31 from './../../Images/Zelle.png';
import Image32 from './../../Images/Zenly.png';
import Image33 from './../../Images/Safari.png';
import Image34 from './../../Images/kakao-talk.png';
import Image35 from './../../Images/Alipay.png';
import Image36 from './../../Images/Restaurant.png';
import Image37 from './../../Images/GoogleReview.png';
import Image38 from './../../Images/Yelp.png';

import "firebase/auth"
import {Firebase} from './../../Config/Firebase/firebase';
import './home.css'
class App extends React.Component {
  constructor(){
    super();
    this.state={
      BGPictuer:"",
      userName:"",
      title:"",
      location:"",
      fullName:"",
      image64Str:"",
      profileUrl:"",
      userStatus:"",
      profilePicture:"",
      profilePictuer:"",
      logoPicture:"",
      showUserData:false,
      isBusiness:false,
      bio:"",
      deps:0,
      socialLinks:[],
      buttonsList:[],
      questions: [
        { id: 'fdsd', title: 'Why is the sky blue?' },
        { id: 'adsf', title: 'Who invented pizza?' },
        { id: 'afdsf', title: 'Is green tea overrated?' },
     ],
        displayQuestions: false,

      sociaImages:[
        {
          Image:Image1,
          imgeName:"Instagram"
        },
        {
          Image:Image2,
          imgeName:"Snapchat"
        },
        {
          Image:Image3,
          imgeName:"Twitter"
        },
        {
          Image:Image4,
          imgeName:"Facebook"
        },
        {
          Image:Image5,
          imgeName:"LinkedIn"
        },
        {
          Image:Image6,
          imgeName:"WhatsApp"
        },
        {
          Image:Image7,
          imgeName:"Email"
        },
        {
          Image:Image8,
          imgeName:"YouTube"
        },
        {
          Image:Image9,
          imgeName:"CashApp"
        },
        {
          Image:Image10,
          imgeName:"Website"
        },
        {
          Image:Image11,
          imgeName:"PayPal"
        },
        {
          Image:Image12,
          imgeName:"Custom URL"
        },
        {
          Image:Image13,
          imgeName:"Skype"
        },
        {
          Image:Image14,
          imgeName:"SoundCloud"
        },
        {
          Image:Image15,
          imgeName:"Spotify"
        },
        {
          Image:Image16,
          imgeName:"Text"
        },
        {
          Image:Image17,
          imgeName:"TikTok"
        },
        {
          Image:Image18,
          imgeName:"Triller"
        },
        {
          Image:Image19,
          imgeName:"Twitch"
        },
        {
          Image:Image20,
          imgeName:"Apple Music"
        }
        ,
        {
          Image:Image21,
          imgeName:"OnlyFans"
        }
        ,
        {
          Image:Image22,
          imgeName:"Venmo"
        }
        ,
        {
          Image:Image23,
          imgeName:"Clubhouse"
        }
        ,
        {
          Image:Image24,
          imgeName:"Phone"
        }
        ,
        {
          Image:Image25,
          imgeName:"Pintrest"
        }
        ,
        {
          Image:Image26,
          imgeName:"Discord"
        },
        {
          Image:Image27,
          imgeName:"Document"
        },
        {
          Image:Image28,
          imgeName:"Menu"
        },
        {
          Image:Image29,
          imgeName:"Reddit"
        },
        {
          Image:Image30,
          imgeName:"WeChat"
        },
        {
          Image:Image31,
          imgeName:"Zelle"
        },
        {
          Image:Image32,
          imgeName:"Zenly"
        },
        {
          Image:Image33,
          imgeName:"Safari"
        },
        {
          Image:Image34,
          imgeName:"kakao-talk"
        },
        {
          Image:Image35,
          imgeName:"Alipay"
        },
        {
          Image:Image36,
          imgeName:"Restaurant"
        },
        {
          Image:Image37,
          imgeName:"GoogleReview"
        },
        {
          Image:Image38,
          imgeName:"Yelp"
        }

      ]
    }
  }

   componentDidMount= async()=>{
     var { socialLinks,sociaImages,buttonsList} = this.state;
    var path = this.props.location.pathname;
    
    var userPath = path.slice(1,path.length)
    console.log(userPath)
      await Firebase.firestore().collection("users").where("userName","==",userPath).get()
      .then((querySnapshot)=>{
        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc)=>{
            var currentUser = doc.data();
            console.log(currentUser.uid)
            var tutorialsRef = Firebase.firestore().collection("users").doc(currentUser.uid);
            var pepsTmp = currentUser.deps
            if (isNaN(pepsTmp)){

              pepsTmp = 0
              currentUser.deps = 0
            }
            tutorialsRef.update({
              deps:  currentUser.deps + 1
            });
            if(currentUser.isDirectOff==="false"){
              if (currentUser.mediaUrl){
                window.location.href = currentUser.mediaUrl;


            }else{
             
              console.log("No such document2222!");
              this.setState({
                showUserData:false,
                userStatus:"Direct link Not Found",
              })
            }
            }
            else{
              var userMediaName = currentUser.isBusiness==true?"businessMedia":"mediaList";
               Firebase.firestore().collection('users').doc(currentUser.uid).collection(userMediaName).get()
               .then((res)=>{
                 res.forEach((doc)=>{
                   var mediaLinks = doc.data()
                   socialLinks.push(mediaLinks)
                  //  console.log(socialLinks)
                   for(var i=0;i<socialLinks.length;i++){
                     for(var j=0; j<sociaImages.length;j++){
                       if(socialLinks[i].mediaImage===sociaImages[j].imgeName){
                        socialLinks[i].mediaImage=sociaImages[j].Image
                       }
                     }
                   }
                   this.setState({socialLinks:socialLinks})
                 })
               })

               buttonsList = []
               var buttonsName = currentUser.isBusiness==true?"busButtons":"buttons";
               Firebase.firestore().collection('users').doc(currentUser.uid).collection(buttonsName).get()
               .then((res)=>{
                 res.forEach((doc)=>{
                  var buttonsListTmp = doc.data()
                  if (buttonsListTmp.isEnabled == true){
                    buttonsList.push(buttonsListTmp)

                  }
                   this.setState({buttonsList:buttonsList})
                 })
               })



               if (currentUser.isBusiness==true) {
                this.setState({
                
                  showUserData:true,
                  isBusiness:currentUser.isBusiness,
                  userName:currentUser.userName,
                  fullName:currentUser.businessName,
                  profileUrl:currentUser.profileUrl,
                  profilePicture:currentUser.businessPicture,
                  profilePictuer:currentUser.profilePictuer,
                  BGPictuer:currentUser.BGPictuer,
                  logoPicture:currentUser.logoPicture,
                  bio:currentUser.businessBio,
                  deps:currentUser.deps
                })
              } else {
                this.setState({
                  title:currentUser.title,
                  location:currentUser.location,
                  showUserData:true,
                  isBusiness:currentUser.isBusiness,
                  userName:currentUser.userName,
                  fullName:currentUser.fullName,
                  profileUrl:currentUser.profileUrl,
                  profilePicture:currentUser.profilePicture,
                  profilePictuer:currentUser.profilePictuer,
                  BGPictuer:currentUser.BGPictuer,
                  bio:currentUser.bio,
                  deps:currentUser.deps
                })
              }

             
            }
        })
        } else {
          console.log("No such document!");
          this.setState({
            showUserData:false,
            userStatus:"User Not Found",
          })
        }
      })
      .catch((error)=>{
        console.log(error)
      })
  }
   
 

iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.userAgentData.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

handleVCF(dispalyName) {
  const FileSaver = require('file-saver');
  console.log('I was triggered during render');
  var socilaURls = "";
  var telNum = "";
  var emailtm = "";
 

  this.state.socialLinks.forEach((doc,index)=>{
      if (doc.mediaName === "Phone"){
        telNum =  "\nTEL;type=CELL;type=VOICE;type=pref:"+doc.mediaId;
      }else if (doc.mediaName === "Email"){
        emailtm =  "\nEMAIL;type=INTERNET;type=pref:"+doc.mediaId;
      }else{
     
      
      if (this.iOS == true){
        var ret = doc.mediaId.replace('https://www.','');
        ret = ret.replace('HTTPS://www.','');
        ret = ret.replace('http://www.','');
        ret = ret.replace('HTTP://www.','');
        socilaURls = socilaURls+"\nX-SOCIALPROFILE;TYPE="+doc.mediaName+":CHARSET=UTF-8:x-user="+ret+":"+doc.mediaUrl;
        }else{
          var newIndex = index+2;
          
          socilaURls = socilaURls+"\nitem"+newIndex+".URL;TYPE="+doc.mediaName+":"+doc.mediaUrl+"\nitem"+newIndex+".X-ABLabel:"+doc.mediaName+";"
        }

      
    }
    })
  var cardVersion = '4.0';
  if (this.iOS == true){
    cardVersion = '4.0';
    }else{
      cardVersion = '3.0';
    }

  var file = new Blob(
    [
`BEGIN:VCARD
VERSION:${cardVersion}
N:${this.state.fullName};;;${telNum}${emailtm}
item1.URL:${this.state.profileUrl}
item1.X-ABLabel:My EZPOP profile${socilaURls}
END:VCARD`
    ],
    { type: "text/vcard;charset=utf-8" }
  );
 var storageRef = Firebase.storage().ref()
  // Upload file and metadata to the object 'images/mountains.jpg'
  var newMetadata = {
    contentType: 'vcard'
  };
  // PHOTO;ENCODING=b;TYPE=JPEG:${this.state.image64Str}

FileSaver.saveAs(file, "blob.vcf");
var uploadTask = storageRef.child('/profile_images/'+this.state.uid + '/contact.vcf').put(file,newMetadata);
  var xyz = this.state.url;
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        console.log('File available at', downloadURL);
        console.log('xyz', xyz);
        var contactRef = Firebase.firestore().collection("users").doc(this.state.uid);
        var contactUrlRef = Firebase.firestore().collection("users").doc(this.state.uid).collection("mediaList").doc("Contact card");
        contactRef.update({
          vcard:  downloadURL
        });
        contactUrlRef.update({
          mediaUrl: downloadURL
        });
        if(this.state.socialLinks>0 && this.state.socialLinks[0].mediaImage === "/static/media/Contactcard.eebbe482.png"){
          contactRef.update({
            mediaUrl: downloadURL,
            vcard:  downloadURL
          });
        }
        var urlVCf = downloadURL;
        this.url = downloadURL
        console.log('urlVCF', urlVCf);
      });
    }
    yourFunction = async () => {
      const delay = ms => new Promise(res => setTimeout(res, ms));
    await delay(500);
    var textName = global.config.i18n.welcome.dispName;

    // imageToBase64(this.state.profilePictuer) // Image URL
    // .then(
    //     (response) => {
    //         console.log(response); 
    //         this.state.image64Str = response;
           
    //         // "iVBORw0KGgoAAAANSwCAIA..."
    //     }
    // )
    // .catch(
    //     (error) => {
    //         console.log(error); // Logs an error if there was one
    //     }
    // )
    // await delay(5000);
    this.handleVCF(textName);
    await delay(1000);
    console.log("Waited 5s");
    let isIOS = this.iOS;
    console.log(isIOS);
    if (this.iOS == true){
    window.location.href = this.state.url;
    }
   
  };
     changeNameHandle = e => {
      this.state.name = (e.target.value)
  };
   changeEmailHandle = e => {
    this.state.email = (e.target.value)
};
 changePhoneHandle = e => {
  this.state.phone = (e.target.value)
};
 changeTitleHandle = e => {
  this.state.title = (e.target.value)
};

displayQuestion = () => {
  this.setState({
      displayQuestions: !this.state.displayQuestions
  })
}

  render() {
    console.log(this.state.socialLinks)
    let questions = null 

    
    if ( this.state.displayQuestions ) {
      questions = (
      <div>
        <Question key=""
                title="" />
      </div>
      )
 }

    document.body.style.backgroundColor = "#ffffff";
     // document.body.style.backgroundImage = "url('https://firebasestorage.googleapis.com/v0/b/tapl-co.appspot.com/o/Web_Background_1024x3072.png?alt=media&token=78c2a400-deab-489a-a46d-89cbb2f12896')";

    return (
      <div > 
        {
          this.state.showUserData?
        <div >
        <Container style={{ width: "100%", alignSelf: 'flex-start'}}>
        <Row className="justify-content-center">
        <Col > 
        <div class="d-flex justify-content-center">
           {/* <img src={noImage} className="_logo"/> */}
  <img src={Logo} onClick={()=>window.location.href="https://www.ezpop.us"} className="_logo"/>
  {/* <img src={noImage} className="_logo"/> */}
  {/* <img
  className="_share-button"
    src={noImage} 
  // onClick={this.displayQuestion}
  /> */}
</div>

   <div>
     
      <div className="d-flex justify-content-center position-relative">
        
      <div className="dotee">  {Dot(this.state.profilePicture === "" || this.state.profilePicture === undefined?this.state.profilePictuer:this.state.profilePicture)} </div> 
        {/* { this.state.isBusiness ? <div className="dotee">  {Dot(this.state.BGPictuer)} </div>: <div className="dotee">  {Dot(this.state.BGPictuer)} </div> } */}
        <img className="_userImg" src={this.state.BGPictuer}  alt="No image" ></img>
      </div>
      </div>
      <div className="d-flex justify-content-between" >
               <p className="_userName" style={{ height:"35px"}}>{this.state.fullName}</p>
               {<img
                 className="_mailBorder"
                 style={{width:"35px",height:"35px"}} onClick={this.yourFunction}
                   src={addTOcontact}
                  
                />} 
           </div>
           <Row className="justify-content-center"  > 
           <p className="_userDes">{this.state.bio}</p></Row>
          
          
           {/* <div className="d-flex justify-content-between"  style={{width:"100%", marginTop:"5px", alignSelf:"center", alignItems:"center" }} > 
<button type="button" className="_buttonDesign" style={{ width:"100%",height:"37px",color: "black", background: "#e5e5e5",fontFamily:"Arial, Helvetica, sans-serif",border:0,fontSize:20, fontWeight:"bold", textAlign:"center", marginLeft:"35px", marginRight:"5px", borderRadius:"15px", alignContent:"center", alignSelf:"center", alignItems:"center" }} onClick={this.yourFunction}>Добави в контакти</button>
 {<img
                 className="_mailBorder"
                 style={{width:"43px",height:"43px"}} onClick={this.yourFunction}
                   src={addTOcontact}
                  
                />} 

</div> */}
               
               <Row className="justify-content-center"  > 
           <p className="_userDes" style={{height:"2px", background: "#FF6B6B"}} ></p></Row>
{/* <Row className="justify-content-center"  style={{height:"2px", background: "#FF6B6B", marginLeft: "15px"}} > 
           </Row> */}
           {/* <p className="_userDes">{this.state.bio}{this.state.title}</p> */}
          {/* <Row className="justify-content-center"  style={{height:"20px"}}> 
           <p className="_userLocation">{this.state.location}</p></Row> */}
{/* <div class="d-flex justify-content-between" style={{width:"80%",margin:"auto",marginTop:5, backgroundColor:"#EBEBEB"  , borderRadius: "20px"}}>
{this.state.buttonsList.length>0?
<button type="button" class="_buttonDesign"  style={{ width:"48%",color: "white", background: "#353963" }} onClick={()=>window.location.href=this.state.buttonsList.length>0?this.state.buttonsList[0]["attachedLink"]:"No attached link"}>{this.state.buttonsList.length>0?this.state.buttonsList[0]["title"]:"No Title"}</button>:null}
{this.state.buttonsList.length>1?<button type="button" class="_buttonDesign" style={{ width:"48%",color: "white", background: "#353963" }} onClick={()=>window.location.href=this.state.buttonsList.length>1?this.state.buttonsList[1]["attachedLink"]:"No attached link"}>{this.state.buttonsList.length>1?this.state.buttonsList[1]["title"]:"No Title"}</button>:null}
</div> */}
 <Row className="justify-content-center"  style={{ padding: 0,width:"100%",margin:"auto",marginTop:0, /*backgroundColor:"#ffffff" */  borderRadius: "0px"}}>
           <Col lg="10" xs="18" >
              <Row className="justify-content-center"  style={{padding: 7, alignSelf: 'flex-start'}}>
             {this.state.socialLinks.sort((a, b) => a.mediaIndex > b.mediaIndex ? 1 : -1).map((val,i)=>{

return(
  <Col lg="4" xs="4"   style={{padding: 4, alignSelf: 'flex-start'}} >
  <div  style={{padding: 10, alignSelf: 'flex-start',/*backgroundColor:"#343434"*/}}>
  <Row className="justify-content-center" >
  <img
                className="_social_image"
                style={{marginTop: "10px",width:"75px",height:"75px"}}
                  src={val.mediaImage}
                  onClick={()=>window.location.href=val.mediaUrl} 
                />
                 </Row>
   {/* <Image style={{padding: 11, alignSelf: 'flex-start',backgroundColor:"#343434"}}  src={val.mediaImage} */}
    {/* onClick={()=>window.location.href=val.mediaUrl} thumbnail />\  */}
    <Row className="justify-content-center" >
    <p  style={{ backgroundColor:"",color: "#8E8E93",marginBottom: "10px"}} className="_caption" onClick={()=>window.location.href=val.mediaUrl}>{val.mediaName} </p>
     </Row>
    </div>
   </Col>
)})}
          </Row>
 </Col>
           </Row>
           </Col>
           </Row>
           {/* <Row className="justify-content-center" >
           <p  style={{
            color: "#343434",
            textAlign: "center",
            width: "100%",
            height:"23px",
            marginBottom:"0px",
            fontSize: 13,
          }}>POWERED BY</p>
           </Row> */}
           <Row className="justify-content-center" >
           <img src={Shop_Ezpop} onClick={()=>window.location.href="https://www.ezpop.us"} className="_downloadBtn"/>
            
           </Row>
           
           <Row className="justify-content-center" >
           <img src={DownloadPLayStore} onClick={()=>window.location.href=" https://play.google.com/store/apps/details?id=com.ezpop.raineri"} className="_downloadBtn"/>
            
           </Row>

           <Row className="justify-content-center" >
           <img src={AppleStore} onClick={()=>window.location.href=" https://apps.apple.com/app/ezpop/id1619541408"} className="_downloadBtn"/>
            
           </Row>
          
{questions}
         </Container>
         <div style={{width:"90%",margin:"auto",marginTop:200,textAlign:"center"}}>
        </div>
        </div>
         :
         <div className="_userNotFoundDiv">
         <p className="_userNotFoundtxt">{this.state.userStatus}</p>
       </div>
         }
      </div>
    );
  }
}
export default App;
