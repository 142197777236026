import React, {  useState }  from 'react';
import { Button } from "react-bootstrap";
import {  Modal } from "react-bootstrap";
import {Firebase} from './../../Config/Firebase/firebase';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './home.css'
const Question = ( props ) => {
 var name ="";
 var email ="" ;
 var phone  ="";
 var title  ="";
    const [show, setShow] = useState(true);
    const changeNameHandle = e => {
      name = (e.target.value)
  }
  const changeEmailHandle = e => {
    email = (e.target.value)
}
const changePhoneHandle = e => {
  phone = (e.target.value)
}
const changeTitleHandle = e => {
  title = (e.target.value)
}
  const handleClose = () => {
    setShow(false);
  // this.displayQuestions = true;
  
  }
  const handleShow = () => setShow(true);
  const addToFirebase = () => {
 const firebase = require("firebase");
      let userTmp = firebase.auth().currentUser; 
      if (userTmp) {
        var anomyUid = userTmp.uid;
        var tutorialsRef = Firebase.firestore().collection("visitors").doc(anomyUid);
        tutorialsRef.set({
          name:  name,
          email:  email,
          phone:  phone,
          title: title,
        });
      } else {
      }
      handleClose();
  };
  return (
  //   <Popup trigger={<button> Trigger</button>} position="right center">
  //   <div><form>
  //              <div className="form-group">
  //                   <input type="email" onChange={ changeNameHandle}   defaultValue = { name } style={{background:"#F5F5F5",color:"#343434",border:0,fontFamily:"Ubuntu,sans-serif",fontSize:13}} className="input_text form-control" placeholder="Full Name" />
  //               </div>
  //               <div className="form-group">
  //                   <input type="email"  onChange={changeEmailHandle} style={{background:"#F5F5F5",color:"#343434",border:0,fontFamily:"Ubuntu,sans-serif",fontSize:13}} className="form-control" placeholder="Your Email" />
  //               </div>
  //               <div className="form-group">
  //                   <input type="email" onChange={changePhoneHandle}  style={{background:"#F5F5F5",color:"#343434",border:0,fontFamily:"Ubuntu,sans-serif",fontSize:13}} className="form-control" placeholder="Your Phone Number" />
  //               </div>
  //               <div className="form-group">
  //                   <input type="email" onChange={changeTitleHandle}  style={{background:"#F5F5F5",color:"#343434",border:0,fontFamily:"Ubuntu,sans-serif",fontSize:13}} className="form-control" placeholder="Title /Role @ Organization" />
  //               </div>               
  //           </form></div>
  // </Popup>
    
         <Modal className="modal"  show={show} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title style={{fontFamily:"Ubuntu,sans-serif",fontSize:23,color:"#343434"}} >SHARE YOUR INFO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form>
               <div className="form-group">
                    <input type="email" onChange={ changeNameHandle}   defaultValue = { name } style={{background:"#F5F5F5",color:"#343434",border:0,fontFamily:"Ubuntu,sans-serif",fontSize:13}} className="input_text form-control" placeholder="Full Name" />
                </div>
                <div className="form-group">
                    <input type="email"  onChange={changeEmailHandle} style={{background:"#F5F5F5",color:"#343434",border:0,fontFamily:"Ubuntu,sans-serif",fontSize:13}} className="form-control" placeholder="Your Email" />
                </div>
                <div className="form-group">
                    <input type="email" onChange={changePhoneHandle}  style={{background:"#F5F5F5",color:"#343434",border:0,fontFamily:"Ubuntu,sans-serif",fontSize:13}} className="form-control" placeholder="Your Phone Number" />
                </div>
                <div className="form-group">
                    <input type="email" onChange={changeTitleHandle}  style={{background:"#F5F5F5",color:"#343434",border:0,fontFamily:"Ubuntu,sans-serif",fontSize:13}} className="form-control" placeholder="Title /Role @ Organization" />
                </div>               
            </form>
</Modal.Body>
        <Modal.Footer>        
          <Button style={{background:"#343434",color:"#ffffff",fontFamily:"Ubuntu,sans-serif",border:0,fontSize:15}}  variant="primary" className="form-control"
           onClick={  ()=>  window.location.href= "mailto:"+props.emailTo+"?subject=KnockMe&body=Hi,%0D%0AName:"+name+""+"%0D%0AEmail:"+email+""+"%0D%0APhone:"+phone+""+"%0D%0ATitle/Role @ Organization:"+title+""
}>
          CONNECT NOW
          </Button>
        </Modal.Footer>
      </Modal>
      
  
 )
 }; 
 export default Question;
