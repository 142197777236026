import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "../../Containers/Home/Home";
import privacyPolicy from "../../Containers/Home/PrivacyPolicy";
import RouteFolder from './../../Containers/routeFolder/route'
import { Switch } from "react-router";


export default class BasicRouter extends React.Component {
  constructor(){
    super();
    this.state={
      userName:""
    }
  }
  render() {
    return (
      <Router>
        <Switch>
        <Route  exact path="/" component={RouteFolder} />
        <Route exact path="/privacy-policy" component={privacyPolicy} />
        <Route  path="/:id" component={Home} />
        
        </Switch>
      </Router>
    );
  }
}
