import React from "react";
import './route.css'

export default class RouteFolder extends React.Component {

  render() {
    return (
      <div className="_main_div">
         <p className="heading">Enter the User Name in path</p>
     </div>
     
    );
  }
}
