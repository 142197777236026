import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyA373xJMxMp6OyadOioEqwZHcbpXDAye_c",
  authDomain: "ezpop-af731.firebaseapp.com",
  projectId: "ezpop-af731",
  storageBucket: "ezpop-af731.appspot.com",
  messagingSenderId: "713247177381",
  appId: "1:713247177381:web:6852d1c2634d9b327f2b4c",
  measurementId: "G-9MY0TWC5T5"
  };
  

  var Firebase =   firebase.initializeApp(firebaseConfig);
  export{
      Firebase
  }