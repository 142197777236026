import React from "react";
import "./App.css";
import BasicRouter from "./Config/Router/Router";
class App extends React.Component {
  render() {
    return <BasicRouter />;
  }
}

export default App;
